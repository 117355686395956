import React from 'react';
import './App.css';

import Main from './components/Main';
import CardContextProvider from './contexts/CardContext';
import Navigation from './components/Navigation';

function App() {
  return (
    <div className="App">
      <CardContextProvider>
        <Navigation />
        <Main />
      </CardContextProvider>
    </div>
  );
}

export default App;
