import baseData from '../data/cards.json';

const shuffle = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export const cardReducer = (state, action) => {
    switch (action.type) {
        case 'NEW_CARD':
            if (!state[action.category] || state[action.category].length === 0) {
                state[action.category] = shuffle([...baseData[action.category]]);
            }

            const listCategories = ["attributeCards", "healthCards"]

            const newCard = state[action.category].pop();

            state = {...state,
                drawnCards: {
                    ...state.drawnCards,
                    [action.category] : listCategories.includes(action.category) ? [...state.drawnCards[action.category], {text: newCard, id: action.theKey}] : newCard
                },
                [action.category] : state[action.category]
            };

            return state
        case "DELETE_CARD":
            state = {...state,
                drawnCards: {
                    ...state.drawnCards,
                    [action.category] : state.drawnCards[`${action.category}`].filter(card => card.id !== action.theKey)
                },
                [action.category] : state[action.category]
            }
            return state;
        case "TOGGLE_SECTION":
            let newInActiveSections = [...state.inActiveSections];
            if (state.inActiveSections.includes(action.section)) {
                newInActiveSections = newInActiveSections.filter(section => section !== action.section);
            } else {
                newInActiveSections.push(action.section);
            }

            state = {...state,
                inActiveSections: newInActiveSections
            };

            return state;
        case "SITE_SECTION":
            state = {...state,
                section: action.section
            };

            return state
        case "UPDATE_SETTING":
            state = {...state,
                settings: {
                    ...state.settings,
                    [`${action.setting}`]: action.value
                }
            };

            return state;

        default:
            return state;
    }
};