import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { CardContext } from '../contexts/CardContext';

const SettingsModal = () => {
    const [show, setShow] = useState(false);
    const { cards, dispatch } = useContext(CardContext);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const updateCardSize = (e) => {
        dispatch({type: "UPDATE_SETTING", setting: "cardSize", value: e.target.value});
    }
  
    return (
      <>
        <Button variant="primary" onClick={handleShow}>
          Inställningar
        </Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Inställningar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicRangeCustom">
                <Form.Label>Storlek på kort ({ cards.settings.cardSize }%)</Form.Label>
                <Form.Control type="range" value={cards.settings.cardSize} custom min="50" max="200" onChange={updateCardSize} />
            </Form.Group>
          </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Stäng
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
export default SettingsModal;