import React, { useContext } from 'react';
import { CardContext } from '../contexts/CardContext';
import SettingsModal from './SettingsModal';
import ResetModal from './ResetModal';
import './Navigation.css';

const Navigation = (props) => {

    const { cards, dispatch } = useContext(CardContext);

    const startSection = () => {
      dispatch({type: "SITE_SECTION", section: 0});
    }

    return (<nav className="navbar navbar-inverted navbar-expand-lg navbar-light">
          <a className="navbar-brand" href="#home" onClick={startSection}>Dynamic person profiling tool</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">

            </ul>
  { cards.section === 1 &&<ResetModal /> }
            <SettingsModal />
          </div>
        </nav>
    );
}

export default Navigation;