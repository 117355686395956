import React from 'react';
import Card from './Card';
import './HealthCard.css';

const HealthCard = (props) => {
  const images = [
    { src: '/images/heart-green.png', className: 'icon-1' },
    { src: '/images/pill-green.png', className: 'icon-2' },
    { src: '/images/doctor-green.png', className: 'icon-4' },
    { src: '/images/patch-green.png', className: 'icon-3' }
  ];

  return (
    <Card className="health-card" theKey={props.theKey} images={images} text={props.text} title1="Hälsa" category="healthCards" smallCard={props.smallCard} />
  )
}

export default HealthCard;