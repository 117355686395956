import React, { createContext, useReducer, useEffect } from 'react';
import { cardReducer } from '../reducers/cardReducer';

export const CardContext = createContext();

const CardContextProvider = (props) => {
    
    const [cards, dispatch] = useReducer(cardReducer, {}, () => {
        const localData = localStorage.getItem('cards');
        if (localData) {
          const data = JSON.parse(localData);
          if (data.version < 1 || data.version === undefined) {
            return {
              genderCards: [],
              ageCards: [],
              livingCards: [],
              attributeCards: [],
              drawnCards: {
                  attributeCards: [],
                  healthCards: []
              },
              inActiveSections: [],
              section: 0,
              settings: {
                cardSize: 100,
                step: 0
              },
              version: 1
          };
          }
          return  JSON.parse(localData);
        } else {
            return {
                genderCards: [],
                ageCards: [],
                livingCards: [],
                attributeCards: [],
                drawnCards: {
                    attributeCards: [],
                    healthCards: []
                },
                inActiveSections: [],
                section: 0,
                settings: {
                  cardSize: 100,
                  step: 0
                },
                version: 1
            };
        }
    });

    useEffect(() => {
        localStorage.setItem('cards', JSON.stringify(cards));
    });

    return (
    <CardContext.Provider value={{cards, dispatch}}>
      {props.children}
    </CardContext.Provider>
  );
}
 
export default CardContextProvider;