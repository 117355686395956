import React from 'react';
import Card from './Card';
import './LivingCard.css';

const LivingCard = (props) => {
    const images = [
        { src: '/images/small-house.png', className: 'icon-2' },
        { src: '/images/appartment.png', className: 'icon-3' },
        { src: '/images/big-house.png', className: 'icon-4'  }
    ];

    return (
        <Card className="living-card" images={images} text={props.text}  title1="Egenskap" title2="Bostadsort" category="livingCards" smallCard={props.smallCard} />
    );
}
 
export default LivingCard;