import React from 'react';
import Card from './Card';
import './AttributeCard.css';

const AttributeCard = (props) => {
  const images = [
    { src: '/images/heart.png', className: 'icon-1' },
    { src: '/images/house.png', className: 'icon-2' },
    { src: '/images/bus.png', className: 'icon-4' },
    { src: '/images/idea.png', className: 'icon-3' }
  ];

  return (
    <Card className="attribute-card" theKey={props.theKey} images={images} text={props.text} title1="Attribut" category="attributeCards" smallCard={props.smallCard} />
  )
}

export default AttributeCard;