import React, { useState, useContext, useEffect } from 'react';
import './Card.css';
import { CardContext } from '../contexts/CardContext';

const Card = (props) => {

    const { cards, dispatch } = useContext(CardContext);

    const [state, setState] = useState({
        active: false,
        activated: false,
        showImages: false,
        newCard: false
    });

    const activateCard = () => {
        if (!state.activated) {

            setTimeout(() => {
                setState({
                    active: true,
                    activated: true,
                    showImages: true,
                    newCard: true
                });
                if (props.category !== "attributeCards" && props.category !== "healthCards") {
                    dispatch({ type: 'NEW_CARD', category: props.category, theKey: props.theKey });
                }
            }, 300);

            setState({ ...state, activated: true });

        }
    }

    const updateCard = () => {

        setState({ ...state, active: false });

        setTimeout(() => {
            dispatch({ type: 'NEW_CARD', category: props.category });
            setState({ ...state, active: true });
        }, 600);
    }

    const deleteCard = () => {
        dispatch({ type: 'DELETE_CARD', category: props.category, theKey: props.theKey });
    }

    const hideImages = () => {
        return props.images.map(image => <img src={image.src} alt={image.alt} className={image.className} key={image.src} />);
    }

    const showImages = () => {
        return props.images.map(image => <img src={image.src} alt={image.alt} className={`show-img ${image.className}`} key={image.src} />);
    }

    const baseSize = {
        height: 344,
        width: 240
    }

    const cardSize = {
        height: baseSize['height'] * (cards.settings.cardSize / 100),
        width: baseSize['width'] * (cards.settings.cardSize / 100),
    }

    const fontSize = {
        fontSize: baseSize['width'] * (cards.settings.cardSize / 100) / 10
    }

    const smallCard = () => {
        return {
            transform: 'scale(0.5)',
            marginTop: -cardSize['height'] / 2 * (1 - 0.55),
            marginBottom: -cardSize['height'] / 2 * (1 - 0.55),
            marginLeft: -cardSize['width'] / 2 * (1 - 0.55),
            marginRight: -cardSize['width'] / 2 * (1 - 0.55),
            height: baseSize['height'] * (cards.settings.cardSize / 100),
            width: baseSize['width'] * (cards.settings.cardSize / 100),
        }
    }

    useEffect(() => {
        if ((props.category === "attributeCards" || props.category === "healthCards") && state.activated === false) {
            setState({ ...state, activated: true });
            setTimeout(() => {
                setState({
                    active: true,
                    activated: true,
                    showImages: true,
                    newCard: true
                });
            }, 300);
        } else if (props.category === "genderCards") {
            if (cards.drawnCards.genderCards !== undefined) {
                setState({ ...state, activated: true });
                setTimeout(() => {
                    setState({
                        active: true,
                        activated: true,
                        showImages: true,
                        newCard: true
                    });
                }, 300);
            }
        } else if (props.category === "livingCards") {
            if (cards.drawnCards.livingCards !== undefined) {
                setState({ ...state, activated: true });
                setTimeout(() => {
                    setState({
                        active: true,
                        activated: true,
                        showImages: true,
                        newCard: true
                    });
                }, 300);
            }
        } else if (props.category === "ageCards") {
            if (cards.drawnCards.ageCards !== undefined) {
                setState({ ...state, activated: true });
                setTimeout(() => {
                    setState({
                        active: true,
                        activated: true,
                        showImages: true,
                        newCard: true
                    });
                }, 300);
            }
        }
    }, [state.newCard]);

    return (
        <div className={`basic-card flip-card ${props.className}`} onClick={activateCard} key={props.theKey} style={props.smallCard === true ? smallCard() : cardSize}>
            <div className={!state.activated ? 'flip-card-inner' : 'flip-card-inner flip-card-inner-active'}>
                <div className="flip-card-front">
                    {props.title1 && <h1 style={{ fontSize: fontSize.fontSize * 1.5 }}>{props.title1}</h1>}
                    {props.title2 && <h2 style={fontSize}>{props.title2}</h2>}
                </div>
                <div className={!state.active ? 'flip-card-back flash' : 'flip-card-back'}>
                    {!state.showImages ? hideImages() : showImages()}
                    <h1 style={fontSize}>{props.text}</h1>
                    {props.category === "attributeCards" || props.category === "healthCards" ?
                        cards.settings.step > 0 ? 
                            <img src='/images/delete.png' alt='Nytt kort' className='delete-card' onClick={deleteCard} />
                            :
                            <div />
                        :
                        <img src='/images/refresh.png' alt='Nytt kort' className='refresh-card' onClick={updateCard} />
                    }
                </div>
            </div>
        </div>
    );
}

export default Card;
