import React, { useContext, useState } from 'react';
import { CardContext } from '../contexts/CardContext';
import { Button, Modal, Form, Row, Col, Container } from 'react-bootstrap';
import './SummaryForm.css';

const SummaryForm = () => {
    const [show, setShow] = useState(false);
    const { cards, dispatch } = useContext(CardContext);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getDate = () => {
        const d = new Date();
        return d.toISOString().split('T')[0]
    };

    const updateName = (e) => {
        dispatch({ type: "UPDATE_SETTING", setting: "name", value: e.target.value });
    }
    const updateReality = (e) => {
        dispatch({ type: "UPDATE_SETTING", setting: "reality", value: e.target.checked });
    }
    const updateText = (e) => {
        dispatch({ type: "UPDATE_SETTING", setting: "text", value: e.target.value });
    }

    return (
        <div id="form-modal">
            <hr />
            <Button variant="success" onClick={handleShow}>
                Formulär
        </Button>
        <hr />

            <Modal show={show} onHide={handleClose} id="form-modal-box" dialogClassName="modal-xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <img src="/images/heart.png" alt="heart" />
                        <img src="/images/doctor-green.png" alt="doctor-green" />
                        <img src="/images/appartment.png" alt="appartment" />
                        <img src="/images/heart-green.png" alt="heart-green" />
                        <img src="/images/old.png" alt="old" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Container>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <h3>Egenskaper</h3>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="4">
                                            Namn
    </Form.Label>
                                        <Col sm="8">
                                            <Form.Control type="text" placeholder="Ange namn på invånarprofilen" value={cards.settings.name} onChange={updateName} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="4">
                                            Ålder
            </Form.Label>
                                        <Col sm="8">
                                            <Form.Control plaintext readOnly defaultValue={cards.drawnCards.ageCards} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="4">
                                            Könsidentitet
            </Form.Label>
                                        <Col sm="8">
                                            <Form.Control plaintext readOnly defaultValue={cards.drawnCards.genderCards} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="4">
                                            Bostadsort
            </Form.Label>
                                        <Col sm="8">
                                            <Form.Control plaintext readOnly defaultValue={cards.drawnCards.livingCards} />
                                        </Col>
                                    </Form.Group>

                                    <h3>Attribut</h3>
                                    {cards.drawnCards.attributeCards.map((card, index) => {
                                        return (
                                            <Form.Group as={Row} key={card.id} className="form-modal-attribute">
                                                <Form.Label column sm="3">
                                                    {index + 1}.
            </Form.Label>
                                                <Col sm="9">
                                                    <Form.Control size="sm" plaintext readOnly defaultValue={card.text} />
                                                </Col>
                                            </Form.Group>)
                                    })}

                                    <h3>Hälsa</h3>
                                    {cards.drawnCards.healthCards.map((card, index) => {
                                        return (
                                            <Form.Group size="sm" as={Row} key={card.id} className="form-modal-attribute">
                                                <Form.Label size="sm" column sm="3">
                                                    {index + 1}.
            </Form.Label>
                                                <Col sm="9">
                                                    <Form.Control size="sm" plaintext readOnly defaultValue={card.text} />
                                                </Col>
                                            </Form.Group>)
                                    })}
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Datum
            </Form.Label>
                                        <Col sm="9">
                                            <Form.Control plaintext readOnly defaultValue={getDate()} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Col sm="12">
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                label="Är profilen rimlig?"
                                                checked={ cards.settings.reality }
                                                onChange={updateReality}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="12">
                                            I VILKA SAMMANHANG KRING VÅRD OCH/ELLER HÄLSA RÖR SIG DENNA PERSON? 
                                            <small>(Träning, sjukvård, hemmet, friskvård, rehabilitering, m.m.)</small>
                                        </Form.Label>
                                        <Col sm="12">
                                        <Form.Control as="textarea" rows="8" value={cards.settings.text} onChange={updateText} />
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Stäng
            </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SummaryForm;