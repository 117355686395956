import React from 'react';
import Card from './Card';
import './AgeCard.css';

const AgeCard = (props) => {
  const images = [
    { src: '/images/child.png', className: 'icon-2' },
    { src: '/images/adult.png', className: 'icon-4' },
    { src: '/images/old.png', className: 'icon-3' }
  ];

  return (
    <Card className="age-card" images={images} text={props.text} title1="Egenskap" title2="Ålder" category="ageCards" smallCard={props.smallCard} />
  )
}

export default AgeCard;