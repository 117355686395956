import React, { useContext, useState } from 'react';
import AgeCard from './AgeCard';
import LivingCard from './LivingCard';
import GenderCard from './GenderCard';
import AttributeCard from './AttributeCard';
import HealthCard from './HealthCard';
import SummaryForm from './SummaryForm';
import { v4 as uuidv4 } from 'uuid';
import './CardList.css';
import { CardContext } from '../contexts/CardContext';
import { Alert, Button } from 'react-bootstrap';

const CardList = () => {
    const { cards, dispatch } = useContext(CardContext);
    const [ state, setState ] = useState({
        activeSection: null
    });

    const attributeImages = [
        { src: '/images/heart.png', className: 'icon-1' },
        { src: '/images/house.png', className: 'icon-2' },
        { src: '/images/bus.png', className: 'icon-4' },
        { src: '/images/idea.png', className: 'icon-3' }
      ];

      const healthImages = [
        { src: '/images/heart-green.png', className: 'icon-1' },
        { src: '/images/pill-green.png', className: 'icon-2' },
        { src: '/images/doctor-green.png', className: 'icon-4' },
        { src: '/images/patch-green.png', className: 'icon-3' }
      ];

      const baseSize = {
        height: 344,
        width: 240
    }


      const cardSize = {
        height: baseSize['height']*(cards.settings.cardSize/100),
        width: baseSize['width']*(cards.settings.cardSize/100),
    }

    const fontSize = {
        fontSize: baseSize['width']*(cards.settings.cardSize/100)/9
    }

    const smallCard = () => {
        return {
            transform: 'scale(0.5)',
            marginTop: -cardSize['height']/2*(1-0.55),
            marginBottom: -cardSize['height']/2*(1-0.55),
            marginLeft: -cardSize['width']/2*(1-0.55),
            marginRight: -cardSize['width']/2*(1-0.55),
            height: baseSize['height']*(cards.settings.cardSize/100),
            width: baseSize['width']*(cards.settings.cardSize/100),
        }
    }

    const newCardAttributeCard = () => {
        const newKey = uuidv4();
        dispatch({type: "NEW_CARD", category: "attributeCards", theKey: newKey})
    }

    const newCardHealthCard = () => {
        const newKey = uuidv4();
        dispatch({type: "NEW_CARD", category: "healthCards", theKey: newKey})
    }

    const changeStep = (step) => {
        setState({...state, activeSection: null});
        dispatch({type: "UPDATE_SETTING", setting: "step", value: step});
    }


    return (
        <div className="card-controller">
            <div id="choose-property-cards">
                { cards.settings.step === 0 && <Alert variant='primary'>
                    <strong>Steg 1.</strong> Börja med att slumpa tre egenskapskort (kön, ålder, bostadsort)
                </Alert> }
                <div className={ cards.settings.step !== 0 && state.activeSection !== 0 ? "basic-cards small-cards" : "basic-cards" }>
                    <GenderCard text={cards.drawnCards.genderCards} smallCard={cards.settings.step !== 0 && state.activeSection !== 0 ? true : false} />
                    <AgeCard text={cards.drawnCards.ageCards} smallCard={cards.settings.step !== 0 && state.activeSection !== 0 ? true : false} />
                    <LivingCard text={cards.drawnCards.livingCards} smallCard={cards.settings.step !== 0 && state.activeSection !== 0? true : false} />
                </div>
                { cards.settings.step === 0 && cards.drawnCards.genderCards && cards.drawnCards.ageCards && cards.drawnCards.livingCards && <Button type="success" onClick={() => {changeStep(1)}}>Nästa steg</Button> }
                { cards.settings.step !== 0 && state.activeSection !== 0 && <img src="/images/zoom-in.png" alt="Zoom in" className="rigth-btn" onClick={() => {setState({...state, activeSection: 0})}} /> }
                { cards.settings.step !== 0 && state.activeSection === 0 && <img src="/images/zoom-out.png" alt="Zoom in" className="rigth-btn" onClick={() => {setState({...state, activeSection: null})}} /> }
            </div>
            <div id="choose-attribute-cards">
                { cards.settings.step === 1 && <Alert variant='primary'>
                    <strong>Steg 2.</strong> Välj så många attributkort som du önskar (klicka på kortet "Attribut" nedan för att generera ett kort)
                </Alert> }
                { cards.settings.step > 0 &&
                <div className={ cards.settings.step !== 1 ? "attribute-cards small-cards" : "attribute-cards" }>
                    { cards.drawnCards.attributeCards && cards.drawnCards.attributeCards.map(card => <AttributeCard text={card.text} key={card.id} theKey={card.id}  smallCard={cards.settings.step !== 1  && state.activeSection !== 1 ? true : false} />) }
                    <div onClick={newCardAttributeCard} id="new-attribute-card" className="basic-card flip-card attribute-card" style={ cards.settings.step !== 1  && state.activeSection !== 1 ? smallCard() : cardSize }>
                        <h1 style={fontSize}>Attribut</h1>
                        {attributeImages.map(image => <img src={image.src} alt={image.alt} className={`show-img ${image.className}`} key={image.src} />)}
                    </div>
                </div>}
                { cards.settings.step === 1 && cards.drawnCards.attributeCards.length > 0 && <Button type="success" onClick={() => {changeStep(2)}}>Nästa steg</Button> }
                { cards.settings.step > 1 && state.activeSection !== 1 && <img src="/images/zoom-in.png" alt="Zoom in" className="rigth-btn" onClick={() => {setState({...state, activeSection: 1})}} /> }
                { cards.settings.step > 1 && state.activeSection === 1 && <img src="/images/zoom-out.png" alt="Zoom in" className="rigth-btn" onClick={() => {setState({...state, activeSection: null})}} /> }
            </div>
            <div id="choose-health-cards">
            { cards.settings.step === 2 && <Alert variant='primary'>
                    <strong>Steg 3.</strong> Välj så många hälsokort som du önskar (klicka på kortet "Hälsa" nedan för att generera ett kort)
                </Alert> }
            { cards.settings.step > 1 &&
                <div className={ cards.settings.step !== 2 ? "health-cards small-cards" : "health-cards" }>
                    { cards.drawnCards.healthCards && cards.drawnCards.healthCards.map(card => <HealthCard text={card.text} key={card.id} theKey={card.id}  smallCard={cards.settings.step !== 2 && state.activeSection !== 2 ? true : false} />) }
                    <div onClick={newCardHealthCard} id="new-health-card" className="basic-card flip-card health-card"  style={ cards.settings.step !== 2  && state.activeSection !== 2 ? smallCard() : cardSize }>
                        <h1 style={fontSize}>Hälsa</h1>
                        {healthImages.map(image => <img src={image.src} alt={image.alt} className={`show-img ${image.className}`} key={image.src} />)}
                    </div>
                </div>}
            { cards.settings.step === 2 && cards.drawnCards.healthCards.length > 0 && <Button type="success" onClick={() => {changeStep(3)}}>Nästa steg</Button> }
            { cards.settings.step > 2 && state.activeSection !== 2 && <img src="/images/zoom-in.png" alt="Zoom in" className="rigth-btn" onClick={() => {setState({...state, activeSection: 2})}} /> }
            { cards.settings.step > 2 && state.activeSection === 2 && <img src="/images/zoom-out.png" alt="Zoom in" className="rigth-btn" onClick={() => {setState({...state, activeSection: null})}} /> }
            </div>

            { cards.settings.step > 2 && <SummaryForm /> }
        </div>
    )

}

export default CardList;