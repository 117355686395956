import React, { useContext } from 'react';

import { CardContext } from '../contexts/CardContext';
import CardList from './CardList';
import Welcome from './Welcome';

const Main = () => {
    const { cards } = useContext(CardContext);
    const element = cards.section === 0 ? <Welcome /> : <CardList />;
    return ( element );
}
 
export default Main;