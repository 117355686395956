import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const SettingsModal = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const resetCards = () => {
        localStorage.clear();
        window.location.reload();
    }

    return (
        <>
            <Button variant="danger" style={{marginRight: "10px"}} onClick={handleShow}>
                Återställ kort
        </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Vill du verkligen återställa korten?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={resetCards} style={{marginRight: "auto"}}>Återställ kort</Button>
                    <Button variant="secondary"  onClick={handleClose}>Avbryt</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SettingsModal;