import React, { useContext } from 'react';
import { CardContext } from '../contexts/CardContext';
import ChooseExistingAction from './ChooseExistingAction';
import './Welcome.css';

const Welcome = () => {
    const { cards, dispatch } = useContext(CardContext);
    const nextSection = () => {
        dispatch({ type: "SITE_SECTION", section: 1 });
    }

    const checkForOldCards = () => {
        if (Object.keys(cards.drawnCards).length > 2) {
            return true;
        } else if (cards.drawnCards.attributeCards.length > 0) {
            return true;
        } else if (cards.drawnCards.healthCards.length > 0) {
            return true;
        }
        return false;
    }

    return (
        <main id="main-welcome">
            <h1>Dynamisk invånarprofil</h1>
            <section>
                <img src="/images/cards.png" alt="Cards" />
                <h3>Hur skapas en invånarprofil?</h3>
                <p>Den invånarprofil som skapas komponeras utifrån:</p>
                <ol>
                    <li>Grundegenskaper som könsidentiteten, ålder och bostadsort. Åldersintervallerna är viktade, dvs de åldrar som oftast kommer i kontakt med vård är mer förkommande.</li>
                    <li>Attribut som beskriver livssituation- -det kan vara grundläggande förutsättningar som arbete och bostad, men också andra aspekter som påverkar välmående, som umgänge, nära relationer och fritidsaktiviteter. Här finns även attribut för om personen behöver tolk, vill förändra sin livssituation etc.</li>
                    <li>Hälsa visar hälso- och sjukdomstillstånd- det kan t ex vara över- eller undervikt, stress, kroniska eller tillfälliga symptom och COVID19.</li>
                </ol>
            </section>
            <hr />
            <section id="welcome-btn-container">
                <button className="btn btn-success" onClick={nextSection}>Klicka här för att börja</button>
            </section>
            <hr />

            { checkForOldCards() && <ChooseExistingAction /> }
        </main>
    );
}

export default Welcome;