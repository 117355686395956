import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { CardContext } from '../contexts/CardContext';

const ChooseExistingAction = () => {
    const [show, setShow] = useState(false);
    const { dispatch } = useContext(CardContext);

    const resetCards = () => {
        localStorage.clear();
        window.location.reload();
    }

    const loadOldCards = () => {
        dispatch({type:"SITE_SECTION", section: 1})
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Modal show={true} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Du har kort sparade!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Vi ser att du har kort sedan tidigare sparade i webbläsaren, hur vill du göra?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={loadOldCards} style={{marginRight: "auto"}}>Ladda gamla kort</Button>
                    <Button variant="success"  onClick={resetCards}>Börja om</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ChooseExistingAction;