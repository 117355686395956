import React from 'react';
import Card from './Card';
import './GenderCard.css';

const GenderCard = (props) => {
    const images =  [
        { src: '/images/man.png', className: 'icon-2' },
        { src: '/images/woman.png', className: 'icon-3' },
        { src: '/images/other.png', className: 'icon-4' }
      ];

    return (
        <Card className="gender-card" images={images} text={props.text}  title1="Egenskap" title2="Kön" category="genderCards" smallCard={props.smallCard} />
    );
}

export default GenderCard;